.section-slider{
  position: relative;
  max-height: 75vh;
  overflow: hidden;
  .slider{
    h3{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      width: 100%;
      text-align: center;
      font-weight: 500;
      color: white;
      text-shadow: 0 0 15px rgba(0,0,0,.75);
      display: none;
      pointer-events: none;
      @include respond-to(small) {
        display: block;
        font-size: 25px;
      }
      @include respond-to(medium) {
        font-size: 40px;
      }
      @include respond-to(large) {
        font-size: 60px;
      }
      span{
        color: $brand-color;
      }
    }
  }
}

.slick-dots{
  @include resetList();
  position: absolute;
  top: 65vh;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: flex;
  align-content: stretch;
  max-width: 100%;
  li{
    display: block;
    margin: 0 4px;
    width: 40px;
    button{
      width: 100%;
      max-width: 100%;
      height: 8px;
      background: white;
      border: none;
      font-size: 0;
      opacity: .65;
      transition: all .25s ease;
    }
    &.slick-active{
      button{
        background: $brand-color;
        opacity: .9;
      }
    }
  }
}