// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'Montserrat', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;


/// Link color
/// @type Color
$link-color: #50b28f;

/// Copy text color
/// @type Color
$text-color: #222222 !default;

/// Main brand color
/// @type Color
$brand-color: #50b28f !default;

/// alternate color
/// @type Color
$alt-color: #ff748c !default;

/// Light brand
/// @type Color
$light-brand: rgb(127, 217, 185) !default;

/// Medium brand
/// @type Color
$mid-brand: rgb(151, 217, 193) !default;

/// Dark brand
/// @type Color
$dark-brand: rgb(26, 116, 84) !default;

/// Very Light grey
/// @type Color
$very-light-grey: #e3e3e3 !default;

/// Light grey
/// @type Color
$light-grey: #676767 !default;

/// Medium grey
/// @type Color
$mid-grey: #545454 !default;

/// Dark grey
/// @type Color
$dark-grey: #414142 !default;

/// Ultra Dark grey
/// @type Color
$ultra-dark-grey: #121212 !default;

/// Mid black
/// @type Color
$mid-black: #0b0b0c !default;

/// background black
/// @type Color
$back-black: #efefef !default;

/// Container's maximum width
/// @type Length
$max-width: 1000px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 600px,
  'medium': 840px,
  'large': 960px,
) !default;