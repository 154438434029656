.modular-text{
  .columns{
    .column.col-6{
      &:first-child{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: center;
        justify-content: center;
      }
      &:last-child{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    @include respond-to(medium) {
      &.right {
        .column.col-6 {
          &:first-child {
            order: 1;
          }
          &:last-child {
            order: 2;
          }
        }
      }
      &.left {
        .column.col-6 {
          &:first-child {
            order: 2;
          }
          &:last-child {
            order: 1;
          }
        }
      }
    }
  }
}

section.modular-text{
  border-top: 10px solid $back-black;
  border-left: 10px solid $back-black;
  border-right: 10px solid $back-black;
}

.modular-text{
  img{
    border-radius: 15px;
  }
  .columns{
    padding: 20px 0;
    .column{
      h2{
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 20px;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 400;
        i.las{
          display: block;
          font-size: 3em;
          margin-bottom: 10px;
        }
        &::after{
          content: "";
          display: block;
          border-bottom: 1px solid currentColor;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 25%;
        }
      }
      img{
        @include respond-to(medium) {
          max-width: 80%;
          margin: 20px auto;
        }
      }
    }
  }
}

.modular-text.opening{
  background: $brand-color;
  color: white;
  .columns{
    .column{
      img{
        max-width: 75%;
        display: none;
        @include respond-to(medium) {
          display: block;
        }
      }
      a{
        display: none;
      }
    }
  }
}

.modular-text.teaser{
  background: $brand-color;
  color: white;
  &:nth-child(odd){
    background: $mid-grey;
  }
  &.trace{
    background: $dark-grey;
  }
  .columns{
    .column{
      img{
        max-width: 75%;
        display: none;
        @include respond-to(medium) {
          display: block;
        }
      }
      a{
        color: white;
        border: 1px solid currentColor;
        padding: .5em 1em;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        margin: 20px 0;
        display: inline-flex;
        align-items: center;
        width: auto;
        span{
          flex-grow: 1;
        }
        i {
          margin-left: .5em;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}

.section-gallery {
  .items:not(.brands){
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    margin: 0 -5px 50px;
    .item{
      width: 100%;
      @include respond-to(medium) {
        width: 50%;
      }
      @include respond-to(large) {
        width: 33.33%;
      }
      max-width: none !important;
      color: #929796;
      margin: 0;
      padding: 5px;
      img{
        display: block;
      }
    }
  }
  .items.brands{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    .item{
      background: white;
      padding: 10px;
      margin: 5px;
      img{
        display: block;
        width: 100px;
        height: auto;
      }
    }
  }
}

.section-pictures {
  border-top: 10px solid $back-black;
  .items{
    .item{
      padding: 2px 1px;
    }
  }
}

.section-map{
  overflow: hidden;
  position: relative;
  & > #map{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .container{
    position: relative;
    z-index: 2;
  }
  .columns{
    margin: 40px auto;
    min-height: 450px;
  }
  .address{
    box-shadow: 5px 5px 10px 0 rgba(0,0,0,.5);
    background: white;
    .container {
      padding: 20px;
      color: $text-color;
      &::before{
        content: normal;
      }
    }
  }
}

.section-trace{
  .items{
    .item{
      display: flex;
      padding-bottom: 20px;
      margin-bottom: 20px;   
      border-bottom: 1px solid $very-light-grey;
      &:last-child{
        border-bottom: none;
      }
      .content{
        flex-grow: 1;
      }
      .pictures{
        display: none;
        @include respond-to(medium) {
          display: block;
        }
        width: 250px;
        min-width: 250px;
        margin-right: 50px;
      }
      .btn{
        background: $brand-color;
        color: white;
      }
      img{
        border-radius: 25px;
      }
    }
  }
}

.modular-banner{
  border-top: 10px solid #efefef;
  border-left: 10px solid #efefef;
  border-right: 10px solid #efefef;
  position: relative;
  overflow: hidden;
  background-position: bottom;
  background-size: cover;
  &::after{
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #685858;
    opacity: .4;
    z-index: 1;
  }
  .item{
    position: relative;
    z-index: 2;
    padding: 20vh 0;
    color: white;
    text-align: center;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    &::before{
      content: "";
      background-image: url(../images/logo-simple.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 15vh;
      width: 15vh;
      position: absolute;
      top: 2.5vh;
      left: 50%;
      transform: translateX(-50%);
    }
    h2{
      font-size: 30px;
      text-transform: uppercase;
      margin: 0;
      @include respond-to(medium) {
        font-size: 50px;
      }
    }
    p{
      margin: 0;
    }
    .content{
      font-size: 18px;
      @include respond-to(medium) {
        font-size: 25px;
      }
    }
    a{
      color: white;
      border: 1px solid currentColor;
      background-color: $brand-color;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
      padding: .5em 1em;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
      margin: 20px 0;
      display: inline-flex;
      align-items: center;
      width: auto;
      span{
        flex-grow: 1;
      }
      i {
        margin-left: .5em;
        display: inline-block;
        vertical-align: middle;
      }
    }

  }
}