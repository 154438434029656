.js-bt-menu{
  outline: none;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  left: 20px;
  top: 40px;
  background: none;
  border: none;
  .text{
    margin-left: 10px;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    line-height: 32px;
    color: $brand-color;
    font-weight: 600;
    transition: all .25s ease;
    opacity: 0;
    transform: translateX(-32px);
    z-index: -1;
    position: relative;
    min-width: 120px;
    min-height: 1em;
    span{
      transition: opacity .25s ease;
      display: block;
      height: 1em;
      position:absolute;
      left: 0;
      top: -5px;
      &.open{
        opacity: 1;
      }
      &.close{
        opacity: 0;
      }
    }       
  }

  @include respond-to(medium) {
    &:hover {
      .text {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  i{
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    &::before,
    &::after,
    span{
      content: "";
      display: block;
      background: $brand-color;
      height: 20%;
      width: 100%;
      position: absolute;
      left: 50%;
      transform-origin: 50% 50%;
      transition: all .25s ease;
    }
    &::before{
      top: 0;
      transform: translate(-50%, 0);
    }
    span{
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &::after{
      bottom: 0;
      transform: translate(-50%, 0);
    }
  }
  @include respond-to(medium){
    &:hover{
      i {
        &::before {
          transform: translate(-65%, 0);
        }
        span{
          opacity: 1;
          transform: translate(-45%, -50%);
        }
        &::after {
          transform: translate(-65%, 0);
        }
      }
    }
  }
}

[id=page-wrapper].open {
  .js-bt-menu {
    i {
      &::before {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      span{
        opacity: 0;
      }
      &::after {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    .text{
      span{
        &.open{
          opacity: 0;
        }
        &.close{
          opacity: 1;
        }
      }
    }
    @include respond-to(medium) {
      &:hover {
        i {
          &::before {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
          &::after {
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }
  }
}


.header{
  text-align: center;
  a {
    color: $brand-color;
  }
  ul {
    @include resetList();
  }
  
  .container {
    max-width: none;
    margin: 0;
    padding: 0;
  }

  .social-links {
    margin: 0;
    display: none;
    @include respond-to(medium) {
      display: block;
      margin: 20px auto;
    }
    ul {
      display: flex;
      li {
        margin: 0 5px;
        width: 16px;
        text-align: center;
        a {
          color: $brand-color;
        }
        span {
          display: none;
        }
        i.la-3x{
          font-size: 1em;
        }
        @include respond-to(medium) {
          width: 32px;
          i.la-3x{
            font-size: 2em;

          }
        }
      }
    }

  }

  .languages-switcher {
    margin: 20px auto;
    ul {
      display: flex;
      li {
        margin: 0 5px;
        a {
          display: block;
          height: 20px;
          width: 20px;
          border-radius: 3px;
          line-height: 20px;
          font-size: 12px;
          text-align: center;
          text-decoration: none;
          font-weight: 500;
          @include respond-to(medium) {
            font-size: 16px;
            border-radius: 6px;
            height: 32px;
            width: 32px;
            line-height: 32px;
          }
          &:hover,
          &.active {
            transition: all .25s ease;
            background: $brand-color;
            color: $mid-black;
          }
        }
        
      }
    }
  }
}

[id=header] {
  padding: 20px;
  min-height: 140px;
  @include respond-to(medium){
    min-height: 180px;
  }
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  color: $brand-color;
  .container{
    display: flex;
    justify-content: flex-end;
  }
  &::before{
    content: "";
    background: $mid-black;
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 150px;
    top: -150px;
    bottom: 0;
    z-index: -1;
    transform: skew(0, -5deg);
    opacity: .825;
  }
  
  .logo {
    width: 85px;
    @include respond-to(medium) {
      width: 150px;
    }
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    a {
      position: relative;
      display: block;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  .group {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include respond-to(medium) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

}

.main-nav.header{
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
  opacity: 0;
  transition: all .5s ease;
  transform: translateY(-150%);
  &::before{
    content: "";
    background: black;
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 150px;
    top: -150px;
    bottom: 0;
    z-index: -1;
    transition: transform .5s .1s ease;
    transform: skew(0, -5deg);
  }

  

  section.logo {
    max-width: 180px;
    margin: 0 auto;
  }

  .navbar{
    font-size: 24px;
    margin: 20px auto 0;
    width: 100%;
    justify-content: center;
    nav{
      ul{
        li{
          text-align: center;
          display: block;
          margin: 10px;
          @include respond-to(medium) {
            display: inline-block;
            margin: 0 10px;
          }
          a{
            text-transform: uppercase;
            color: $brand-color;
            font-weight: 400;
          }
        }
      }
    }
  }

  .group{
    margin: 0 auto;
    section.social-links{
      display: block;
      position: relative;
      margin-bottom: 40px;
      &::after{
        content: "";
        width: 100px;
        border-bottom: 1px solid $brand-color;
        position: absolute;
        bottom: -20px;
        left: 50%;
        margin-left: -50px;
      }
    }
    ul{
      justify-content: center;
    }
  }
}

[id=page-wrapper] {
  background: white;
  &:before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.85);
    transition: opacity .5s ease;
  }
  &.open {
    &:before {
      transform: translateY(0);
      opacity: 1;
      z-index: 6;
    }
    .main-nav {
      opacity: 1;
      transform: translateY(0);
      &::before {
        transform: skew(0deg, 5deg);
      }
      & > .js-bt {
        opacity: 1;
      }
    }
  }
}