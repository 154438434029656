// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------


.copyright {
  border: 10px solid $back-black;
  background: $ultra-dark-grey;
  .content {
    padding: 20px 0;
    text-align: center;
    color: $light-grey;
    p{
      margin: 0;
    }
    .icons {
      i {
        margin: 0 .25em;
      }
    }
    p {
      font-size: 12px;
    }
  }
}

.navbar-section-footer{
  background: $back-black;
  text-align: center;
  nav{
    justify-content: center;
  }
  ul{
    @include resetList();
    text-align: center;
    padding: 40px 0;
    font-size: 20px;
    font-weight: 400;
    li{
      display: block;
      margin: .5em;
      position: relative;
      @include respond-to(medium) {
        display: inline-block;
        margin: 0 .5em;
        &::after{
          content: "";
          height: 1em;
          width: .15em;
          background:$text-color;
          position: absolute;
          right: -.75em;
          top: 50%;
          bottom: 0;
          transform: translateY(-50%) skewX(-20deg);
        }
        &:last-child::after{
          content: normal;
        }
      }
      a{
        color: $text-color;
        text-transform: uppercase;
      }
    }
  }
}

.address{
  background: $text-color;
  .container{
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    color: white;
    @include respond-to(medium) {
      width: 55%;
      padding: 85px 160px 85px 0;
      &::before{
        content: "";
        display: block;
        width: 220px;
        height: 220px;
        position: absolute;
        right: -50px;
        top: 65px;
        background: url(../images/cat.svg) no-repeat center center;
        background-size: contain;
        opacity: .65;
      }
    }

    .title{
      font-size: 24px;
      font-weight: 200;
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 20px;
      &::after{
        content: "";
        display: block;
        border-bottom: 1px solid $alt-color;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 25%;
      }
    }

    .content{
      font-size: 18px;
      line-height: 1.6em;
    }

  }
}

.quick-access{
  background: $mid-black;
  .container{
    padding: 10px;
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    & > ul{
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      @include respond-to(large) {
        flex-direction: row;
      }
      li{
        margin: 10px;
        display: flex;
        width: 100%;
        height: 100%; 
        a{
          display: block;
          border: 1px solid $light-grey;
          background: $ultra-dark-grey;
          padding: 20px 20px 5px;
          width: 100%;
          color: white;
          text-transform: uppercase;
          position: relative;
          text-align: center;
          text-decoration: none;
          font-size: 14px;
          span{
            display: block;
            min-height: 3em;
          }
          .tel-number{
            white-space: nowrap;
          }
          i{
            color: $brand-color;
            display: block;
            font-size: 72px;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

section.strava{
  color: white;
  background: $light-grey;
  display: none;
  @include respond-to(medium) {
    display: block;
  }
  .container{
    padding: 20px;
    overflow: hidden;
    .iframe{
      background: #FC4C02;
      width: 460px;
      margin: 0 auto;
    }
  }
}

.social-links.bottom {
  .container {
    max-width: none;
    background: $dark-grey;
    padding: 0;
    margin: 0;
    .content {
      padding: 0;
      margin: 0;
    }
    ul {
      display: flex;
      height: 100%;
      align-content: stretch;
      align-items: center;
      @include resetList();
      li {
        text-align: center;
        flex-grow: 1;
        a {
          margin: 20px auto;
          width: 72px;
          height: 72px;
          background: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          color: $brand-color;
          border: 1px solid $mid-black;
          background: $ultra-dark-grey;
          span {
            display: none;
          }
        }
        &.facebook {
          a {
            // background: $dark-grey;
            // color: $mid-grey;
          }
        }
        &.instagram {          
          a {
            // background: $mid-grey;
            // color: $mid-grey;
          }
        }
        &.strava {
          a {
            // background: $light-grey;
            // color: $light-grey;
          }
        }
        &.twitter {
          background: $light-grey;
          a {
            color: $light-grey;
          }
        }
      }
    }
  }
}

.region-footer {
  @include respond-to(medium) {
    display: flex;
    align-content: stretch;
    align-content: stretch;
    flex-wrap: wrap;
    section {
      display: flex;
      flex-grow: 1;
      width: 50%;
    }
  }
}

.region-footer{
  border: 5px solid $back-black;
  & > section{
    border: 5px solid $back-black;
  }
} 

// //////////////////////////////


footer {
  .footer_bottom {
    section {
      color: white;
      &:first-child {
        background: $dark-grey;

      }
      &:last-child {
        background: $mid-black;
      }

      &[id=block-adresse] {
        .container {
          &::before {
            background-image: url(../images/form-white.svg);
          }
        }
      }
      &.menu{
        nav{
          display: flex;
          height: 100%;
          & > ul{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            margin: 20px 0;
            li{
              display: block;
              width: 265px;

              a{
                display: block;
                border: 2px solid white;
                padding: 10px 20px 10px 40px;
                width: 100%;
                color: white;
                text-transform: uppercase;
                position: relative;
                text-align: center;
                text-decoration: none;
                &:before{
                  font-family: FontAwesome;
                  content: "@";
                  display: block;
                  font-size: 1.5em;
                  position: absolute;
                  top: 50%;
                  left: 10px;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
  }

  @include respond-to(medium) {
    .footer_bottom {
      display: flex;
      align-content: stretch;
      section {
        display: flex;
        flex-grow: 1;
        width: 50%;
        & > * {
          width: $max-width/2;
          margin: 0;
          padding: 85px 60px;
          max-width: none;
        }
        &:first-child {
          justify-content: flex-end;
        }
        &:last-child {
          justify-content: flex-start;
        }
      }
    }
  }

  .social-links {
    .container {
      max-width: none;
      border-top: 4px solid white;
      padding: 0;
      margin: 0;
      .content {
        padding: 0;
        margin: 0;
      }

      ul {
        display: flex;
        align-content: stretch;
        @include resetList();
        li {
          text-align: center;
          flex-grow: 1;
          a {
            margin: 20px auto;
            width: 72px;
            height: 72px;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            span {
              display: none;
            }
          }
          &.facebook {
            background: $dark-grey;
            a {
              color: $dark-grey;
            }
          }
          &.instagram {
            background: $mid-grey;
            a {
              color: $mid-grey;
            }
          }
          &.twitter {
            background: $light-grey;
            a {
              color: $light-grey;
            }
          }
        }
      }
    }
  }
}
