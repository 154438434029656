// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Managing Responsive Breakpoints
/// https://www.sitepoint.com/managing-responsive-breakpoints-sass/
// See Breakpoints in _variables.scss
@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }
    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin resetList() {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  li{
    margin: 0;
    padding: 0;
    &:before {
      display: none;
    }
  }
}

@mixin resetButton() {
  margin: 0 auto;
  padding: 0;
  border: none;
  background: none;
  color: inherit;
}