$la-font-path: "../fonts" !default;
$la-font-size-base: 14px !default;
$la-line-height-base: 1 !default;
$la-border-color: #eee !default;
$la-inverse: #fff !default;
$la-version: 1.3.0 !default;
$la-li-width: (20em / 14) !default;

@function la-content($la-var) {
  @return unquote("\"#{ $la-var }\"");
}

$la-css-prefix: la;

$la-font-name-lar: Line Awesome Free !default;
$la-css-prefix-lar: lar !default;

$la-font-name-las: Line Awesome Free !default;
$la-css-prefix-las: las !default;

$la-font-name-lab: Line Awesome Brands !default;
$la-css-prefix-lab: lab !default;

$la-500px: \f26e;
$la-accessible-icon: \f368;
$la-accusoft: \f369;
$la-acquisitions-incorporated: \f6af;
$la-ad: \f641;
$la-address-book: \f2b9;
$la-address-card: \f2bb;
$la-adjust: \f042;
$la-adn: \f170;
$la-adobe: \f778;
$la-adversal: \f36a;
$la-affiliatetheme: \f36b;
$la-air-freshener: \f5d0;
$la-airbnb: \f834;
$la-algolia: \f36c;
$la-align-center: \f037;
$la-align-justify: \f039;
$la-align-left: \f036;
$la-align-right: \f038;
$la-alipay: \f642;
$la-allergies: \f461;
$la-amazon: \f270;
$la-amazon-pay: \f42c;
$la-ambulance: \f0f9;
$la-american-sign-language-interpreting: \f2a3;
$la-amilia: \f36d;
$la-anchor: \f13d;
$la-android: \f17b;
$la-angellist: \f209;
$la-angle-double-down: \f103;
$la-angle-double-left: \f100;
$la-angle-double-right: \f101;
$la-angle-double-up: \f102;
$la-angle-down: \f107;
$la-angle-left: \f104;
$la-angle-right: \f105;
$la-angle-up: \f106;
$la-angry: \f556;
$la-angrycreative: \f36e;
$la-angular: \f420;
$la-ankh: \f644;
$la-app-store: \f36f;
$la-app-store-ios: \f370;
$la-apper: \f371;
$la-apple: \f179;
$la-apple-alt: \f5d1;
$la-apple-pay: \f415;
$la-archive: \f187;
$la-archway: \f557;
$la-arrow-alt-circle-down: \f358;
$la-arrow-alt-circle-left: \f359;
$la-arrow-alt-circle-right: \f35a;
$la-arrow-alt-circle-up: \f35b;
$la-arrow-circle-down: \f0ab;
$la-arrow-circle-left: \f0a8;
$la-arrow-circle-right: \f0a9;
$la-arrow-circle-up: \f0aa;
$la-arrow-down: \f063;
$la-arrow-left: \f060;
$la-arrow-right: \f061;
$la-arrow-up: \f062;
$la-arrows-alt: \f0b2;
$la-arrows-alt-h: \f337;
$la-arrows-alt-v: \f338;
$la-artstation: \f77a;
$la-assistive-listening-systems: \f2a2;
$la-asterisk: \f069;
$la-asymmetrik: \f372;
$la-at: \f1fa;
$la-atlas: \f558;
$la-atlassian: \f77b;
$la-atom: \f5d2;
$la-audible: \f373;
$la-audio-description: \f29e;
$la-autoprefixer: \f41c;
$la-avianex: \f374;
$la-aviato: \f421;
$la-award: \f559;
$la-aws: \f375;
$la-baby: \f77c;
$la-baby-carriage: \f77d;
$la-backspace: \f55a;
$la-backward: \f04a;
$la-bacon: \f7e5;
$la-balance-scale: \f24e;
$la-balance-scale-left: \f515;
$la-balance-scale-right: \f516;
$la-ban: \f05e;
$la-band-aid: \f462;
$la-bandcamp: \f2d5;
$la-barcode: \f02a;
$la-bars: \f0c9;
$la-baseball-ball: \f433;
$la-basketball-ball: \f434;
$la-bath: \f2cd;
$la-battery-empty: \f244;
$la-battery-full: \f240;
$la-battery-half: \f242;
$la-battery-quarter: \f243;
$la-battery-three-quarters: \f241;
$la-battle-net: \f835;
$la-bed: \f236;
$la-beer: \f0fc;
$la-behance: \f1b4;
$la-behance-square: \f1b5;
$la-bell: \f0f3;
$la-bell-slash: \f1f6;
$la-bezier-curve: \f55b;
$la-bible: \f647;
$la-bicycle: \f206;
$la-biking: \f84a;
$la-bimobject: \f378;
$la-binoculars: \f1e5;
$la-biohazard: \f780;
$la-birthday-cake: \f1fd;
$la-bitbucket: \f171;
$la-bitcoin: \f379;
$la-bity: \f37a;
$la-black-tie: \f27e;
$la-blackberry: \f37b;
$la-blender: \f517;
$la-blender-phone: \f6b6;
$la-blind: \f29d;
$la-blog: \f781;
$la-blogger: \f37c;
$la-blogger-b: \f37d;
$la-bluetooth: \f293;
$la-bluetooth-b: \f294;
$la-bold: \f032;
$la-bolt: \f0e7;
$la-bomb: \f1e2;
$la-bone: \f5d7;
$la-bong: \f55c;
$la-book: \f02d;
$la-book-dead: \f6b7;
$la-book-medical: \f7e6;
$la-book-open: \f518;
$la-book-reader: \f5da;
$la-bookmark: \f02e;
$la-bootstrap: \f836;
$la-border-all: \f84c;
$la-border-none: \f850;
$la-border-style: \f853;
$la-bowling-ball: \f436;
$la-box: \f466;
$la-box-open: \f49e;
$la-boxes: \f468;
$la-braille: \f2a1;
$la-brain: \f5dc;
$la-bread-slice: \f7ec;
$la-briefcase: \f0b1;
$la-briefcase-medical: \f469;
$la-broadcast-tower: \f519;
$la-broom: \f51a;
$la-brush: \f55d;
$la-btc: \f15a;
$la-buffer: \f837;
$la-bug: \f188;
$la-building: \f1ad;
$la-bullhorn: \f0a1;
$la-bullseye: \f140;
$la-burn: \f46a;
$la-buromobelexperte: \f37f;
$la-bus: \f207;
$la-bus-alt: \f55e;
$la-business-time: \f64a;
$la-buysellads: \f20d;
$la-calculator: \f1ec;
$la-calendar: \f133;
$la-calendar-alt: \f073;
$la-calendar-check: \f274;
$la-calendar-day: \f783;
$la-calendar-minus: \f272;
$la-calendar-plus: \f271;
$la-calendar-times: \f273;
$la-calendar-week: \f784;
$la-camera: \f030;
$la-camera-retro: \f083;
$la-campground: \f6bb;
$la-canadian-maple-leaf: \f785;
$la-candy-cane: \f786;
$la-cannabis: \f55f;
$la-capsules: \f46b;
$la-car: \f1b9;
$la-car-alt: \f5de;
$la-car-battery: \f5df;
$la-car-crash: \f5e1;
$la-car-side: \f5e4;
$la-caret-down: \f0d7;
$la-caret-left: \f0d9;
$la-caret-right: \f0da;
$la-caret-square-down: \f150;
$la-caret-square-left: \f191;
$la-caret-square-right: \f152;
$la-caret-square-up: \f151;
$la-caret-up: \f0d8;
$la-carrot: \f787;
$la-cart-arrow-down: \f218;
$la-cart-plus: \f217;
$la-cash-register: \f788;
$la-cat: \f6be;
$la-cc-amazon-pay: \f42d;
$la-cc-amex: \f1f3;
$la-cc-apple-pay: \f416;
$la-cc-diners-club: \f24c;
$la-cc-discover: \f1f2;
$la-cc-jcb: \f24b;
$la-cc-mastercard: \f1f1;
$la-cc-paypal: \f1f4;
$la-cc-stripe: \f1f5;
$la-cc-visa: \f1f0;
$la-centercode: \f380;
$la-centos: \f789;
$la-certificate: \f0a3;
$la-chair: \f6c0;
$la-chalkboard: \f51b;
$la-chalkboard-teacher: \f51c;
$la-charging-station: \f5e7;
$la-chart-area: \f1fe;
$la-chart-bar: \f080;
$la-chart-line: \f201;
$la-chart-pie: \f200;
$la-check: \f00c;
$la-check-circle: \f058;
$la-check-double: \f560;
$la-check-square: \f14a;
$la-cheese: \f7ef;
$la-chess: \f439;
$la-chess-bishop: \f43a;
$la-chess-board: \f43c;
$la-chess-king: \f43f;
$la-chess-knight: \f441;
$la-chess-pawn: \f443;
$la-chess-queen: \f445;
$la-chess-rook: \f447;
$la-chevron-circle-down: \f13a;
$la-chevron-circle-left: \f137;
$la-chevron-circle-right: \f138;
$la-chevron-circle-up: \f139;
$la-chevron-down: \f078;
$la-chevron-left: \f053;
$la-chevron-right: \f054;
$la-chevron-up: \f077;
$la-child: \f1ae;
$la-chrome: \f268;
$la-chromecast: \f838;
$la-church: \f51d;
$la-circle: \f111;
$la-circle-notch: \f1ce;
$la-city: \f64f;
$la-clinic-medical: \f7f2;
$la-clipboard: \f328;
$la-clipboard-check: \f46c;
$la-clipboard-list: \f46d;
$la-clock: \f017;
$la-clone: \f24d;
$la-closed-captioning: \f20a;
$la-cloud: \f0c2;
$la-cloud-download-alt: \f381;
$la-cloud-meatball: \f73b;
$la-cloud-moon: \f6c3;
$la-cloud-moon-rain: \f73c;
$la-cloud-rain: \f73d;
$la-cloud-showers-heavy: \f740;
$la-cloud-sun: \f6c4;
$la-cloud-sun-rain: \f743;
$la-cloud-upload-alt: \f382;
$la-cloudscale: \f383;
$la-cloudsmith: \f384;
$la-cloudversify: \f385;
$la-cocktail: \f561;
$la-code: \f121;
$la-code-branch: \f126;
$la-codepen: \f1cb;
$la-codiepie: \f284;
$la-coffee: \f0f4;
$la-cog: \f013;
$la-cogs: \f085;
$la-coins: \f51e;
$la-columns: \f0db;
$la-comment: \f075;
$la-comment-alt: \f27a;
$la-comment-dollar: \f651;
$la-comment-dots: \f4ad;
$la-comment-medical: \f7f5;
$la-comment-slash: \f4b3;
$la-comments: \f086;
$la-comments-dollar: \f653;
$la-compact-disc: \f51f;
$la-compass: \f14e;
$la-compress: \f066;
$la-compress-arrows-alt: \f78c;
$la-concierge-bell: \f562;
$la-confluence: \f78d;
$la-connectdevelop: \f20e;
$la-contao: \f26d;
$la-cookie: \f563;
$la-cookie-bite: \f564;
$la-copy: \f0c5;
$la-copyright: \f1f9;
$la-cotton-bureau: \f89e;
$la-couch: \f4b8;
$la-cpanel: \f388;
$la-creative-commons: \f25e;
$la-creative-commons-by: \f4e7;
$la-creative-commons-nc: \f4e8;
$la-creative-commons-nc-eu: \f4e9;
$la-creative-commons-nc-jp: \f4ea;
$la-creative-commons-nd: \f4eb;
$la-creative-commons-pd: \f4ec;
$la-creative-commons-pd-alt: \f4ed;
$la-creative-commons-remix: \f4ee;
$la-creative-commons-sa: \f4ef;
$la-creative-commons-sampling: \f4f0;
$la-creative-commons-sampling-plus: \f4f1;
$la-creative-commons-share: \f4f2;
$la-creative-commons-zero: \f4f3;
$la-credit-card: \f09d;
$la-critical-role: \f6c9;
$la-crop: \f125;
$la-crop-alt: \f565;
$la-cross: \f654;
$la-crosshairs: \f05b;
$la-crow: \f520;
$la-crown: \f521;
$la-crutch: \f7f7;
$la-css3: \f13c;
$la-css3-alt: \f38b;
$la-cube: \f1b2;
$la-cubes: \f1b3;
$la-cut: \f0c4;
$la-cuttlefish: \f38c;
$la-d-and-d: \f38d;
$la-d-and-d-beyond: \f6ca;
$la-dashcube: \f210;
$la-database: \f1c0;
$la-deaf: \f2a4;
$la-delicious: \f1a5;
$la-democrat: \f747;
$la-deploydog: \f38e;
$la-deskpro: \f38f;
$la-desktop: \f108;
$la-dev: \f6cc;
$la-deviantart: \f1bd;
$la-dharmachakra: \f655;
$la-dhl: \f790;
$la-diagnoses: \f470;
$la-diaspora: \f791;
$la-dice: \f522;
$la-dice-d20: \f6cf;
$la-dice-d6: \f6d1;
$la-dice-five: \f523;
$la-dice-four: \f524;
$la-dice-one: \f525;
$la-dice-six: \f526;
$la-dice-three: \f527;
$la-dice-two: \f528;
$la-digg: \f1a6;
$la-digital-ocean: \f391;
$la-digital-tachograph: \f566;
$la-directions: \f5eb;
$la-discord: \f392;
$la-discourse: \f393;
$la-divide: \f529;
$la-dizzy: \f567;
$la-dna: \f471;
$la-dochub: \f394;
$la-docker: \f395;
$la-dog: \f6d3;
$la-dollar-sign: \f155;
$la-dolly: \f472;
$la-dolly-flatbed: \f474;
$la-donate: \f4b9;
$la-door-closed: \f52a;
$la-door-open: \f52b;
$la-dot-circle: \f192;
$la-dove: \f4ba;
$la-download: \f019;
$la-draft2digital: \f396;
$la-drafting-compass: \f568;
$la-dragon: \f6d5;
$la-draw-polygon: \f5ee;
$la-dribbble: \f17d;
$la-dribbble-square: \f397;
$la-dropbox: \f16b;
$la-drum: \f569;
$la-drum-steelpan: \f56a;
$la-drumstick-bite: \f6d7;
$la-drupal: \f1a9;
$la-dumbbell: \f44b;
$la-dumpster: \f793;
$la-dumpster-fire: \f794;
$la-dungeon: \f6d9;
$la-dyalog: \f399;
$la-earlybirds: \f39a;
$la-ebay: \f4f4;
$la-edge: \f282;
$la-edit: \f044;
$la-egg: \f7fb;
$la-eject: \f052;
$la-elementor: \f430;
$la-ellipsis-h: \f141;
$la-ellipsis-v: \f142;
$la-ello: \f5f1;
$la-ember: \f423;
$la-empire: \f1d1;
$la-envelope: \f0e0;
$la-envelope-open: \f2b6;
$la-envelope-open-text: \f658;
$la-envelope-square: \f199;
$la-envira: \f299;
$la-equals: \f52c;
$la-eraser: \f12d;
$la-erlang: \f39d;
$la-ethereum: \f42e;
$la-ethernet: \f796;
$la-etsy: \f2d7;
$la-euro-sign: \f153;
$la-evernote: \f839;
$la-exchange-alt: \f362;
$la-exclamation: \f12a;
$la-exclamation-circle: \f06a;
$la-exclamation-triangle: \f071;
$la-expand: \f065;
$la-expand-arrows-alt: \f31e;
$la-expeditedssl: \f23e;
$la-external-link-alt: \f35d;
$la-external-link-square-alt: \f360;
$la-eye: \f06e;
$la-eye-dropper: \f1fb;
$la-eye-slash: \f070;
$la-facebook: \f09a;
$la-facebook-f: \f39e;
$la-facebook-messenger: \f39f;
$la-facebook-square: \f082;
$la-fan: \f863;
$la-fantasy-flight-games: \f6dc;
$la-fast-backward: \f049;
$la-fast-forward: \f050;
$la-fax: \f1ac;
$la-feather: \f52d;
$la-feather-alt: \f56b;
$la-fedex: \f797;
$la-fedora: \f798;
$la-female: \f182;
$la-fighter-jet: \f0fb;
$la-figma: \f799;
$la-file: \f15b;
$la-file-alt: \f15c;
$la-file-archive: \f1c6;
$la-file-audio: \f1c7;
$la-file-code: \f1c9;
$la-file-contract: \f56c;
$la-file-csv: \f6dd;
$la-file-download: \f56d;
$la-file-excel: \f1c3;
$la-file-export: \f56e;
$la-file-image: \f1c5;
$la-file-import: \f56f;
$la-file-invoice: \f570;
$la-file-invoice-dollar: \f571;
$la-file-medical: \f477;
$la-file-medical-alt: \f478;
$la-file-pdf: \f1c1;
$la-file-powerpoint: \f1c4;
$la-file-prescription: \f572;
$la-file-signature: \f573;
$la-file-upload: \f574;
$la-file-video: \f1c8;
$la-file-word: \f1c2;
$la-fill: \f575;
$la-fill-drip: \f576;
$la-film: \f008;
$la-filter: \f0b0;
$la-fingerprint: \f577;
$la-fire: \f06d;
$la-fire-alt: \f7e4;
$la-fire-extinguisher: \f134;
$la-firefox: \f269;
$la-first-aid: \f479;
$la-first-order: \f2b0;
$la-first-order-alt: \f50a;
$la-firstdraft: \f3a1;
$la-fish: \f578;
$la-fist-raised: \f6de;
$la-flag: \f024;
$la-flag-checkered: \f11e;
$la-flag-usa: \f74d;
$la-flask: \f0c3;
$la-flickr: \f16e;
$la-flipboard: \f44d;
$la-flushed: \f579;
$la-fly: \f417;
$la-folder: \f07b;
$la-folder-minus: \f65d;
$la-folder-open: \f07c;
$la-folder-plus: \f65e;
$la-font: \f031;
$la-font-awesome: \f2b4;
$la-font-awesome-alt: \f35c;
$la-font-awesome-flag: \f425;
$la-fonticons: \f280;
$la-fonticons-fi: \f3a2;
$la-football-ball: \f44e;
$la-fort-awesome: \f286;
$la-fort-awesome-alt: \f3a3;
$la-forumbee: \f211;
$la-forward: \f04e;
$la-foursquare: \f180;
$la-free-code-camp: \f2c5;
$la-freebsd: \f3a4;
$la-frog: \f52e;
$la-frown: \f119;
$la-frown-open: \f57a;
$la-fulcrum: \f50b;
$la-funnel-dollar: \f662;
$la-futbol: \f1e3;
$la-galactic-republic: \f50c;
$la-galactic-senate: \f50d;
$la-gamepad: \f11b;
$la-gas-pump: \f52f;
$la-gavel: \f0e3;
$la-gem: \f3a5;
$la-genderless: \f22d;
$la-get-pocket: \f265;
$la-gg: \f260;
$la-gg-circle: \f261;
$la-ghost: \f6e2;
$la-gift: \f06b;
$la-gifts: \f79c;
$la-git: \f1d3;
$la-git-alt: \f841;
$la-git-square: \f1d2;
$la-github: \f09b;
$la-github-alt: \f113;
$la-github-square: \f092;
$la-gitkraken: \f3a6;
$la-gitlab: \f296;
$la-gitter: \f426;
$la-glass-cheers: \f79f;
$la-glass-martini: \f000;
$la-glass-martini-alt: \f57b;
$la-glass-whiskey: \f7a0;
$la-glasses: \f530;
$la-glide: \f2a5;
$la-glide-g: \f2a6;
$la-globe: \f0ac;
$la-globe-africa: \f57c;
$la-globe-americas: \f57d;
$la-globe-asia: \f57e;
$la-globe-europe: \f7a2;
$la-gofore: \f3a7;
$la-golf-ball: \f450;
$la-goodreads: \f3a8;
$la-goodreads-g: \f3a9;
$la-google: \f1a0;
$la-google-drive: \f3aa;
$la-google-play: \f3ab;
$la-google-plus: \f2b3;
$la-google-plus-g: \f0d5;
$la-google-plus-square: \f0d4;
$la-google-wallet: \f1ee;
$la-gopuram: \f664;
$la-graduation-cap: \f19d;
$la-gratipay: \f184;
$la-grav: \f2d6;
$la-greater-than: \f531;
$la-greater-than-equal: \f532;
$la-grimace: \f57f;
$la-grin: \f580;
$la-grin-alt: \f581;
$la-grin-beam: \f582;
$la-grin-beam-sweat: \f583;
$la-grin-hearts: \f584;
$la-grin-squint: \f585;
$la-grin-squint-tears: \f586;
$la-grin-stars: \f587;
$la-grin-tears: \f588;
$la-grin-tongue: \f589;
$la-grin-tongue-squint: \f58a;
$la-grin-tongue-wink: \f58b;
$la-grin-wink: \f58c;
$la-grip-horizontal: \f58d;
$la-grip-lines: \f7a4;
$la-grip-lines-vertical: \f7a5;
$la-grip-vertical: \f58e;
$la-gripfire: \f3ac;
$la-grunt: \f3ad;
$la-guitar: \f7a6;
$la-gulp: \f3ae;
$la-h-square: \f0fd;
$la-hacker-news: \f1d4;
$la-hacker-news-square: \f3af;
$la-hackerrank: \f5f7;
$la-hamburger: \f805;
$la-hammer: \f6e3;
$la-hamsa: \f665;
$la-hand-holding: \f4bd;
$la-hand-holding-heart: \f4be;
$la-hand-holding-usd: \f4c0;
$la-hand-lizard: \f258;
$la-hand-middle-finger: \f806;
$la-hand-paper: \f256;
$la-hand-peace: \f25b;
$la-hand-point-down: \f0a7;
$la-hand-point-left: \f0a5;
$la-hand-point-right: \f0a4;
$la-hand-point-up: \f0a6;
$la-hand-pointer: \f25a;
$la-hand-rock: \f255;
$la-hand-scissors: \f257;
$la-hand-spock: \f259;
$la-hands: \f4c2;
$la-hands-helping: \f4c4;
$la-handshake: \f2b5;
$la-hanukiah: \f6e6;
$la-hard-hat: \f807;
$la-hashtag: \f292;
$la-hat-wizard: \f6e8;
$la-haykal: \f666;
$la-hdd: \f0a0;
$la-heading: \f1dc;
$la-headphones: \f025;
$la-headphones-alt: \f58f;
$la-headset: \f590;
$la-heart: \f004;
$la-heart-broken: \f7a9;
$la-heartbeat: \f21e;
$la-helicopter: \f533;
$la-highlighter: \f591;
$la-hiking: \f6ec;
$la-hippo: \f6ed;
$la-hips: \f452;
$la-hire-a-helper: \f3b0;
$la-history: \f1da;
$la-hockey-puck: \f453;
$la-holly-berry: \f7aa;
$la-home: \f015;
$la-hooli: \f427;
$la-hornbill: \f592;
$la-horse: \f6f0;
$la-horse-head: \f7ab;
$la-hospital: \f0f8;
$la-hospital-alt: \f47d;
$la-hospital-symbol: \f47e;
$la-hot-tub: \f593;
$la-hotdog: \f80f;
$la-hotel: \f594;
$la-hotjar: \f3b1;
$la-hourglass: \f254;
$la-hourglass-end: \f253;
$la-hourglass-half: \f252;
$la-hourglass-start: \f251;
$la-house-damage: \f6f1;
$la-houzz: \f27c;
$la-hryvnia: \f6f2;
$la-html5: \f13b;
$la-hubspot: \f3b2;
$la-i-cursor: \f246;
$la-ice-cream: \f810;
$la-icicles: \f7ad;
$la-icons: \f86d;
$la-id-badge: \f2c1;
$la-id-card: \f2c2;
$la-id-card-alt: \f47f;
$la-igloo: \f7ae;
$la-image: \f03e;
$la-images: \f302;
$la-imdb: \f2d8;
$la-inbox: \f01c;
$la-indent: \f03c;
$la-industry: \f275;
$la-infinity: \f534;
$la-info: \f129;
$la-info-circle: \f05a;
$la-instagram: \f16d;
$la-intercom: \f7af;
$la-internet-explorer: \f26b;
$la-invision: \f7b0;
$la-ioxhost: \f208;
$la-italic: \f033;
$la-itch-io: \f83a;
$la-itunes: \f3b4;
$la-itunes-note: \f3b5;
$la-java: \f4e4;
$la-jedi: \f669;
$la-jedi-order: \f50e;
$la-jenkins: \f3b6;
$la-jira: \f7b1;
$la-joget: \f3b7;
$la-joint: \f595;
$la-joomla: \f1aa;
$la-journal-whills: \f66a;
$la-js: \f3b8;
$la-js-square: \f3b9;
$la-jsfiddle: \f1cc;
$la-kaaba: \f66b;
$la-kaggle: \f5fa;
$la-key: \f084;
$la-keybase: \f4f5;
$la-keyboard: \f11c;
$la-keycdn: \f3ba;
$la-khanda: \f66d;
$la-kickstarter: \f3bb;
$la-kickstarter-k: \f3bc;
$la-kiss: \f596;
$la-kiss-beam: \f597;
$la-kiss-wink-heart: \f598;
$la-kiwi-bird: \f535;
$la-korvue: \f42f;
$la-landmark: \f66f;
$la-language: \f1ab;
$la-laptop: \f109;
$la-laptop-code: \f5fc;
$la-laptop-medical: \f812;
$la-laravel: \f3bd;
$la-lastfm: \f202;
$la-lastfm-square: \f203;
$la-laugh: \f599;
$la-laugh-beam: \f59a;
$la-laugh-squint: \f59b;
$la-laugh-wink: \f59c;
$la-layer-group: \f5fd;
$la-leaf: \f06c;
$la-leanpub: \f212;
$la-lemon: \f094;
$la-less: \f41d;
$la-less-than: \f536;
$la-less-than-equal: \f537;
$la-level-down-alt: \f3be;
$la-level-up-alt: \f3bf;
$la-life-ring: \f1cd;
$la-lightbulb: \f0eb;
$la-line: \f3c0;
$la-link: \f0c1;
$la-linkedin: \f08c;
$la-linkedin-in: \f0e1;
$la-linode: \f2b8;
$la-linux: \f17c;
$la-lira-sign: \f195;
$la-list: \f03a;
$la-list-alt: \f022;
$la-list-ol: \f0cb;
$la-list-ul: \f0ca;
$la-location-arrow: \f124;
$la-lock: \f023;
$la-lock-open: \f3c1;
$la-long-arrow-alt-down: \f309;
$la-long-arrow-alt-left: \f30a;
$la-long-arrow-alt-right: \f30b;
$la-long-arrow-alt-up: \f30c;
$la-low-vision: \f2a8;
$la-luggage-cart: \f59d;
$la-lyft: \f3c3;
$la-magento: \f3c4;
$la-magic: \f0d0;
$la-magnet: \f076;
$la-mail-bulk: \f674;
$la-mailchimp: \f59e;
$la-male: \f183;
$la-mandalorian: \f50f;
$la-map: \f279;
$la-map-marked: \f59f;
$la-map-marked-alt: \f5a0;
$la-map-marker: \f041;
$la-map-marker-alt: \f3c5;
$la-map-pin: \f276;
$la-map-signs: \f277;
$la-markdown: \f60f;
$la-marker: \f5a1;
$la-mars: \f222;
$la-mars-double: \f227;
$la-mars-stroke: \f229;
$la-mars-stroke-h: \f22b;
$la-mars-stroke-v: \f22a;
$la-mask: \f6fa;
$la-mastodon: \f4f6;
$la-maxcdn: \f136;
$la-medal: \f5a2;
$la-medapps: \f3c6;
$la-medium: \f23a;
$la-medium-m: \f3c7;
$la-medkit: \f0fa;
$la-medrt: \f3c8;
$la-meetup: \f2e0;
$la-megaport: \f5a3;
$la-meh: \f11a;
$la-meh-blank: \f5a4;
$la-meh-rolling-eyes: \f5a5;
$la-memory: \f538;
$la-mendeley: \f7b3;
$la-menorah: \f676;
$la-mercury: \f223;
$la-meteor: \f753;
$la-microchip: \f2db;
$la-microphone: \f130;
$la-microphone-alt: \f3c9;
$la-microphone-alt-slash: \f539;
$la-microphone-slash: \f131;
$la-microscope: \f610;
$la-microsoft: \f3ca;
$la-minus: \f068;
$la-minus-circle: \f056;
$la-minus-square: \f146;
$la-mitten: \f7b5;
$la-mix: \f3cb;
$la-mixcloud: \f289;
$la-mizuni: \f3cc;
$la-mobile: \f10b;
$la-mobile-alt: \f3cd;
$la-modx: \f285;
$la-monero: \f3d0;
$la-money-bill: \f0d6;
$la-money-bill-alt: \f3d1;
$la-money-bill-wave: \f53a;
$la-money-bill-wave-alt: \f53b;
$la-money-check: \f53c;
$la-money-check-alt: \f53d;
$la-monument: \f5a6;
$la-moon: \f186;
$la-mortar-pestle: \f5a7;
$la-mosque: \f678;
$la-motorcycle: \f21c;
$la-mountain: \f6fc;
$la-mouse-pointer: \f245;
$la-mug-hot: \f7b6;
$la-music: \f001;
$la-napster: \f3d2;
$la-neos: \f612;
$la-network-wired: \f6ff;
$la-neuter: \f22c;
$la-newspaper: \f1ea;
$la-nimblr: \f5a8;
$la-node: \f419;
$la-node-js: \f3d3;
$la-not-equal: \f53e;
$la-notes-medical: \f481;
$la-npm: \f3d4;
$la-ns8: \f3d5;
$la-nutritionix: \f3d6;
$la-object-group: \f247;
$la-object-ungroup: \f248;
$la-odnoklassniki: \f263;
$la-odnoklassniki-square: \f264;
$la-oil-can: \f613;
$la-old-republic: \f510;
$la-om: \f679;
$la-opencart: \f23d;
$la-openid: \f19b;
$la-opera: \f26a;
$la-optin-monster: \f23c;
$la-osi: \f41a;
$la-otter: \f700;
$la-outdent: \f03b;
$la-page4: \f3d7;
$la-pagelines: \f18c;
$la-pager: \f815;
$la-paint-brush: \f1fc;
$la-paint-roller: \f5aa;
$la-palette: \f53f;
$la-palfed: \f3d8;
$la-pallet: \f482;
$la-paper-plane: \f1d8;
$la-paperclip: \f0c6;
$la-parachute-box: \f4cd;
$la-paragraph: \f1dd;
$la-parking: \f540;
$la-passport: \f5ab;
$la-pastafarianism: \f67b;
$la-paste: \f0ea;
$la-patreon: \f3d9;
$la-pause: \f04c;
$la-pause-circle: \f28b;
$la-paw: \f1b0;
$la-paypal: \f1ed;
$la-peace: \f67c;
$la-pen: \f304;
$la-pen-alt: \f305;
$la-pen-fancy: \f5ac;
$la-pen-nib: \f5ad;
$la-pen-square: \f14b;
$la-pencil-alt: \f303;
$la-pencil-ruler: \f5ae;
$la-penny-arcade: \f704;
$la-people-carry: \f4ce;
$la-pepper-hot: \f816;
$la-percent: \f295;
$la-percentage: \f541;
$la-periscope: \f3da;
$la-person-booth: \f756;
$la-phabricator: \f3db;
$la-phoenix-framework: \f3dc;
$la-phoenix-squadron: \f511;
$la-phone: \f095;
$la-phone-alt: \f879;
$la-phone-slash: \f3dd;
$la-phone-square: \f098;
$la-phone-square-alt: \f87b;
$la-phone-volume: \f2a0;
$la-photo-video: \f87c;
$la-php: \f457;
$la-pied-piper: \f2ae;
$la-pied-piper-alt: \f1a8;
$la-pied-piper-hat: \f4e5;
$la-pied-piper-pp: \f1a7;
$la-piggy-bank: \f4d3;
$la-pills: \f484;
$la-pinterest: \f0d2;
$la-pinterest-p: \f231;
$la-pinterest-square: \f0d3;
$la-pizza-slice: \f818;
$la-place-of-worship: \f67f;
$la-plane: \f072;
$la-plane-arrival: \f5af;
$la-plane-departure: \f5b0;
$la-play: \f04b;
$la-play-circle: \f144;
$la-playstation: \f3df;
$la-plug: \f1e6;
$la-plus: \f067;
$la-plus-circle: \f055;
$la-plus-square: \f0fe;
$la-podcast: \f2ce;
$la-poll: \f681;
$la-poll-h: \f682;
$la-poo: \f2fe;
$la-poo-storm: \f75a;
$la-poop: \f619;
$la-portrait: \f3e0;
$la-pound-sign: \f154;
$la-power-off: \f011;
$la-pray: \f683;
$la-praying-hands: \f684;
$la-prescription: \f5b1;
$la-prescription-bottle: \f485;
$la-prescription-bottle-alt: \f486;
$la-print: \f02f;
$la-procedures: \f487;
$la-product-hunt: \f288;
$la-project-diagram: \f542;
$la-pushed: \f3e1;
$la-puzzle-piece: \f12e;
$la-python: \f3e2;
$la-qq: \f1d6;
$la-qrcode: \f029;
$la-question: \f128;
$la-question-circle: \f059;
$la-quidditch: \f458;
$la-quinscape: \f459;
$la-quora: \f2c4;
$la-quote-left: \f10d;
$la-quote-right: \f10e;
$la-quran: \f687;
$la-r-project: \f4f7;
$la-radiation: \f7b9;
$la-radiation-alt: \f7ba;
$la-rainbow: \f75b;
$la-random: \f074;
$la-raspberry-pi: \f7bb;
$la-ravelry: \f2d9;
$la-react: \f41b;
$la-reacteurope: \f75d;
$la-readme: \f4d5;
$la-rebel: \f1d0;
$la-receipt: \f543;
$la-recycle: \f1b8;
$la-red-river: \f3e3;
$la-reddit: \f1a1;
$la-reddit-alien: \f281;
$la-reddit-square: \f1a2;
$la-redhat: \f7bc;
$la-redo: \f01e;
$la-redo-alt: \f2f9;
$la-registered: \f25d;
$la-remove-format: \f87d;
$la-renren: \f18b;
$la-reply: \f3e5;
$la-reply-all: \f122;
$la-replyd: \f3e6;
$la-republican: \f75e;
$la-researchgate: \f4f8;
$la-resolving: \f3e7;
$la-restroom: \f7bd;
$la-retweet: \f079;
$la-rev: \f5b2;
$la-ribbon: \f4d6;
$la-ring: \f70b;
$la-road: \f018;
$la-robot: \f544;
$la-rocket: \f135;
$la-rocketchat: \f3e8;
$la-rockrms: \f3e9;
$la-route: \f4d7;
$la-rss: \f09e;
$la-rss-square: \f143;
$la-ruble-sign: \f158;
$la-ruler: \f545;
$la-ruler-combined: \f546;
$la-ruler-horizontal: \f547;
$la-ruler-vertical: \f548;
$la-running: \f70c;
$la-rupee-sign: \f156;
$la-sad-cry: \f5b3;
$la-sad-tear: \f5b4;
$la-safari: \f267;
$la-salesforce: \f83b;
$la-sass: \f41e;
$la-satellite: \f7bf;
$la-satellite-dish: \f7c0;
$la-save: \f0c7;
$la-schlix: \f3ea;
$la-school: \f549;
$la-screwdriver: \f54a;
$la-scribd: \f28a;
$la-scroll: \f70e;
$la-sd-card: \f7c2;
$la-search: \f002;
$la-search-dollar: \f688;
$la-search-location: \f689;
$la-search-minus: \f010;
$la-search-plus: \f00e;
$la-searchengin: \f3eb;
$la-seedling: \f4d8;
$la-sellcast: \f2da;
$la-sellsy: \f213;
$la-server: \f233;
$la-servicestack: \f3ec;
$la-shapes: \f61f;
$la-share: \f064;
$la-share-alt: \f1e0;
$la-share-alt-square: \f1e1;
$la-share-square: \f14d;
$la-shekel-sign: \f20b;
$la-shield-alt: \f3ed;
$la-ship: \f21a;
$la-shipping-fast: \f48b;
$la-shirtsinbulk: \f214;
$la-shoe-prints: \f54b;
$la-shopping-bag: \f290;
$la-shopping-basket: \f291;
$la-shopping-cart: \f07a;
$la-shopware: \f5b5;
$la-shower: \f2cc;
$la-shuttle-van: \f5b6;
$la-sign: \f4d9;
$la-sign-in-alt: \f2f6;
$la-sign-language: \f2a7;
$la-sign-out-alt: \f2f5;
$la-signal: \f012;
$la-signature: \f5b7;
$la-sim-card: \f7c4;
$la-simplybuilt: \f215;
$la-sistrix: \f3ee;
$la-sitemap: \f0e8;
$la-sith: \f512;
$la-skating: \f7c5;
$la-sketch: \f7c6;
$la-skiing: \f7c9;
$la-skiing-nordic: \f7ca;
$la-skull: \f54c;
$la-skull-crossbones: \f714;
$la-skyatlas: \f216;
$la-skype: \f17e;
$la-slack: \f198;
$la-slack-hash: \f3ef;
$la-slash: \f715;
$la-sleigh: \f7cc;
$la-sliders-h: \f1de;
$la-slideshare: \f1e7;
$la-smile: \f118;
$la-smile-beam: \f5b8;
$la-smile-wink: \f4da;
$la-smog: \f75f;
$la-smoking: \f48d;
$la-smoking-ban: \f54d;
$la-sms: \f7cd;
$la-snapchat: \f2ab;
$la-snapchat-ghost: \f2ac;
$la-snapchat-square: \f2ad;
$la-snowboarding: \f7ce;
$la-snowflake: \f2dc;
$la-snowman: \f7d0;
$la-snowplow: \f7d2;
$la-socks: \f696;
$la-solar-panel: \f5ba;
$la-sort: \f0dc;
$la-sort-alpha-down: \f15d;
$la-sort-alpha-down-alt: \f881;
$la-sort-alpha-up: \f15e;
$la-sort-alpha-up-alt: \f882;
$la-sort-amount-down: \f160;
$la-sort-amount-down-alt: \f884;
$la-sort-amount-up: \f161;
$la-sort-amount-up-alt: \f885;
$la-sort-down: \f0dd;
$la-sort-numeric-down: \f162;
$la-sort-numeric-down-alt: \f886;
$la-sort-numeric-up: \f163;
$la-sort-numeric-up-alt: \f887;
$la-sort-up: \f0de;
$la-soundcloud: \f1be;
$la-sourcetree: \f7d3;
$la-spa: \f5bb;
$la-space-shuttle: \f197;
$la-speakap: \f3f3;
$la-speaker-deck: \f83c;
$la-spell-check: \f891;
$la-spider: \f717;
$la-spinner: \f110;
$la-splotch: \f5bc;
$la-spotify: \f1bc;
$la-spray-can: \f5bd;
$la-square: \f0c8;
$la-square-full: \f45c;
$la-square-root-alt: \f698;
$la-squarespace: \f5be;
$la-stack-exchange: \f18d;
$la-stack-overflow: \f16c;
$la-stackpath: \f842;
$la-stamp: \f5bf;
$la-star: \f005;
$la-star-and-crescent: \f699;
$la-star-half: \f089;
$la-star-half-alt: \f5c0;
$la-star-of-david: \f69a;
$la-star-of-life: \f621;
$la-staylinked: \f3f5;
$la-steam: \f1b6;
$la-steam-square: \f1b7;
$la-steam-symbol: \f3f6;
$la-step-backward: \f048;
$la-step-forward: \f051;
$la-stethoscope: \f0f1;
$la-sticker-mule: \f3f7;
$la-sticky-note: \f249;
$la-stop: \f04d;
$la-stop-circle: \f28d;
$la-stopwatch: \f2f2;
$la-store: \f54e;
$la-store-alt: \f54f;
$la-strava: \f428;
$la-stream: \f550;
$la-street-view: \f21d;
$la-strikethrough: \f0cc;
$la-stripe: \f429;
$la-stripe-s: \f42a;
$la-stroopwafel: \f551;
$la-studiovinari: \f3f8;
$la-stumbleupon: \f1a4;
$la-stumbleupon-circle: \f1a3;
$la-subscript: \f12c;
$la-subway: \f239;
$la-suitcase: \f0f2;
$la-suitcase-rolling: \f5c1;
$la-sun: \f185;
$la-superpowers: \f2dd;
$la-superscript: \f12b;
$la-supple: \f3f9;
$la-surprise: \f5c2;
$la-suse: \f7d6;
$la-swatchbook: \f5c3;
$la-swimmer: \f5c4;
$la-swimming-pool: \f5c5;
$la-symfony: \f83d;
$la-synagogue: \f69b;
$la-sync: \f021;
$la-sync-alt: \f2f1;
$la-syringe: \f48e;
$la-table: \f0ce;
$la-table-tennis: \f45d;
$la-tablet: \f10a;
$la-tablet-alt: \f3fa;
$la-tablets: \f490;
$la-tachometer-alt: \f3fd;
$la-tag: \f02b;
$la-tags: \f02c;
$la-tape: \f4db;
$la-tasks: \f0ae;
$la-taxi: \f1ba;
$la-teamspeak: \f4f9;
$la-teeth: \f62e;
$la-teeth-open: \f62f;
$la-telegram: \f2c6;
$la-telegram-plane: \f3fe;
$la-temperature-high: \f769;
$la-temperature-low: \f76b;
$la-tencent-weibo: \f1d5;
$la-tenge: \f7d7;
$la-terminal: \f120;
$la-text-height: \f034;
$la-text-width: \f035;
$la-th: \f00a;
$la-th-large: \f009;
$la-th-list: \f00b;
$la-the-red-yeti: \f69d;
$la-theater-masks: \f630;
$la-themeco: \f5c6;
$la-themeisle: \f2b2;
$la-thermometer: \f491;
$la-thermometer-empty: \f2cb;
$la-thermometer-full: \f2c7;
$la-thermometer-half: \f2c9;
$la-thermometer-quarter: \f2ca;
$la-thermometer-three-quarters: \f2c8;
$la-think-peaks: \f731;
$la-thumbs-down: \f165;
$la-thumbs-up: \f164;
$la-thumbtack: \f08d;
$la-ticket-alt: \f3ff;
$la-times: \f00d;
$la-times-circle: \f057;
$la-tint: \f043;
$la-tint-slash: \f5c7;
$la-tired: \f5c8;
$la-toggle-off: \f204;
$la-toggle-on: \f205;
$la-toilet: \f7d8;
$la-toilet-paper: \f71e;
$la-toolbox: \f552;
$la-tools: \f7d9;
$la-tooth: \f5c9;
$la-torah: \f6a0;
$la-torii-gate: \f6a1;
$la-tractor: \f722;
$la-trade-federation: \f513;
$la-trademark: \f25c;
$la-traffic-light: \f637;
$la-train: \f238;
$la-tram: \f7da;
$la-transgender: \f224;
$la-transgender-alt: \f225;
$la-trash: \f1f8;
$la-trash-alt: \f2ed;
$la-trash-restore: \f829;
$la-trash-restore-alt: \f82a;
$la-tree: \f1bb;
$la-trello: \f181;
$la-tripadvisor: \f262;
$la-trophy: \f091;
$la-truck: \f0d1;
$la-truck-loading: \f4de;
$la-truck-monster: \f63b;
$la-truck-moving: \f4df;
$la-truck-pickup: \f63c;
$la-tshirt: \f553;
$la-tty: \f1e4;
$la-tumblr: \f173;
$la-tumblr-square: \f174;
$la-tv: \f26c;
$la-twitch: \f1e8;
$la-twitter: \f099;
$la-twitter-square: \f081;
$la-typo3: \f42b;
$la-uber: \f402;
$la-ubuntu: \f7df;
$la-uikit: \f403;
$la-umbrella: \f0e9;
$la-umbrella-beach: \f5ca;
$la-underline: \f0cd;
$la-undo: \f0e2;
$la-undo-alt: \f2ea;
$la-uniregistry: \f404;
$la-universal-access: \f29a;
$la-university: \f19c;
$la-unlink: \f127;
$la-unlock: \f09c;
$la-unlock-alt: \f13e;
$la-untappd: \f405;
$la-upload: \f093;
$la-ups: \f7e0;
$la-usb: \f287;
$la-user: \f007;
$la-user-alt: \f406;
$la-user-alt-slash: \f4fa;
$la-user-astronaut: \f4fb;
$la-user-check: \f4fc;
$la-user-circle: \f2bd;
$la-user-clock: \f4fd;
$la-user-cog: \f4fe;
$la-user-edit: \f4ff;
$la-user-friends: \f500;
$la-user-graduate: \f501;
$la-user-injured: \f728;
$la-user-lock: \f502;
$la-user-md: \f0f0;
$la-user-minus: \f503;
$la-user-ninja: \f504;
$la-user-nurse: \f82f;
$la-user-plus: \f234;
$la-user-secret: \f21b;
$la-user-shield: \f505;
$la-user-slash: \f506;
$la-user-tag: \f507;
$la-user-tie: \f508;
$la-user-times: \f235;
$la-users: \f0c0;
$la-users-cog: \f509;
$la-usps: \f7e1;
$la-ussunnah: \f407;
$la-utensil-spoon: \f2e5;
$la-utensils: \f2e7;
$la-vaadin: \f408;
$la-vector-square: \f5cb;
$la-venus: \f221;
$la-venus-double: \f226;
$la-venus-mars: \f228;
$la-viacoin: \f237;
$la-viadeo: \f2a9;
$la-viadeo-square: \f2aa;
$la-vial: \f492;
$la-vials: \f493;
$la-viber: \f409;
$la-video: \f03d;
$la-video-slash: \f4e2;
$la-vihara: \f6a7;
$la-vimeo: \f40a;
$la-vimeo-square: \f194;
$la-vimeo-v: \f27d;
$la-vine: \f1ca;
$la-vk: \f189;
$la-vnv: \f40b;
$la-voicemail: \f897;
$la-volleyball-ball: \f45f;
$la-volume-down: \f027;
$la-volume-mute: \f6a9;
$la-volume-off: \f026;
$la-volume-up: \f028;
$la-vote-yea: \f772;
$la-vr-cardboard: \f729;
$la-vuejs: \f41f;
$la-walking: \f554;
$la-wallet: \f555;
$la-warehouse: \f494;
$la-water: \f773;
$la-wave-square: \f83e;
$la-waze: \f83f;
$la-weebly: \f5cc;
$la-weibo: \f18a;
$la-weight: \f496;
$la-weight-hanging: \f5cd;
$la-weixin: \f1d7;
$la-whatsapp: \f232;
$la-whatsapp-square: \f40c;
$la-wheelchair: \f193;
$la-whmcs: \f40d;
$la-wifi: \f1eb;
$la-wikipedia-w: \f266;
$la-wind: \f72e;
$la-window-close: \f410;
$la-window-maximize: \f2d0;
$la-window-minimize: \f2d1;
$la-window-restore: \f2d2;
$la-windows: \f17a;
$la-wine-bottle: \f72f;
$la-wine-glass: \f4e3;
$la-wine-glass-alt: \f5ce;
$la-wix: \f5cf;
$la-wizards-of-the-coast: \f730;
$la-wolf-pack-battalion: \f514;
$la-won-sign: \f159;
$la-wordpress: \f19a;
$la-wordpress-simple: \f411;
$la-wpbeginner: \f297;
$la-wpexplorer: \f2de;
$la-wpforms: \f298;
$la-wpressr: \f3e4;
$la-wrench: \f0ad;
$la-x-ray: \f497;
$la-xbox: \f412;
$la-xing: \f168;
$la-xing-square: \f169;
$la-y-combinator: \f23b;
$la-yahoo: \f19e;
$la-yammer: \f840;
$la-yandex: \f413;
$la-yandex-international: \f414;
$la-yarn: \f7e3;
$la-yelp: \f1e9;
$la-yen-sign: \f157;
$la-yin-yang: \f6ad;
$la-yoast: \f2b1;
$la-youtube: \f167;
$la-youtube-square: \f431;
$la-zhihu: \f63f;
$la-hat-cowboy: \f8c0;
$la-hat-cowboy-side: \f8c1;
$la-mdb: \f8ca;
$la-mouse: \f8cc;
$la-orcid: \f8d2;
$la-record-vinyl: \f8d9;
$la-swift: \f8e1;
$la-umbraco: \f8e8;
$la-buy-n-large: \f8a6;
