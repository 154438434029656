body {
    font-size: 16px;
    font-weight: 300;
    color: $text-color;
    font-family: "Montserrat","Helvetica Neue Light","Helvetica Neue","Helvetica","Arial",sans-serif;
	background: $light-grey;
}
img{
	max-width: 100%;
	height: auto;
}

a{
	color: $brand-color;
	&:hover{
		color: $brand-color;
	}
}

#page-wrapper{
	position: relative;
	margin: 0 auto;
	max-width: 1920px;
	background: $back-black;
	padding-top: 0;

}

.page-content{
	padding: 0 0 50px 0;
}

body.home{
	.page-content{
		text-align: center;
		font-size: 20px;
	}
}

section.container{
	position: relative;
}

.page-title {
	margin-top: 20px;
	text-align: center;
	font-size: 28px;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	padding: 60px 0 20px;
	i {
		opacity: .75;
		font-weight: 800;
		color: $brand-color;	
		display: block;
		width: auto;
		height: auto;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: -10px;
		font-size: 2.5em;
		&.logo{
			opacity: 1;
			width: 40px;
			height: 60px;
			background: url(../images/logo-coffee.svg) no-repeat center center;
			background-size: contain;
		}
	}
	span {
	  display: inline-block;
	  position: relative;
	  padding-bottom: 20px;
	  &::after {
		content: "";
		display: block;
		border-bottom: 1px solid $brand-color;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 50%;
	  }
	}
}

.btn, .button{
	color: $brand-color;
	border-color: $brand-color;
	&:focus, 
	&:hover {
    border-color: $brand-color;
		background: lighten($brand-color,40%);
		color: $mid-black;
	}
}

nav a{
	transition: all 0.35s ease;
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-color: transparent;
	text-decoration-thickness: 0;
	text-underline-offset: 0.45em;
	&.active,
	&:focus,
	&:hover{
		text-decoration-color: currentColor;
		text-decoration-thickness: 0.15em;
		text-underline-offset: 0.35em;
	}
}