#breadcrumbs{
  padding: 0;
  a{
    color: $brand-color;
  }
  span{
    padding: 0;
    &.btn{
      padding: .25rem .4rem;
      pointer-events: none;
      border-color: #ccc;
      color: #ccc;
    }
  }
}

.snipcart-summary{
  position: absolute;
  top: 10px;
  right: 0;
  .snipcart-total-items {
    position: absolute;
    background: $brand-color;
    color: white;
    font-size: 16px;
    display: block;
    top: -12px;
    right: -26px;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
}
}


.snipcart-thumb{  
  
  .snipcart-price{
    position: absolute;
    top: -5px;
    right: -20px;
    height: 75px;
    line-height: 75px;
    text-align: center;
    width: 75px;
    background: #000;
    border-radius: 100%;
    color: #fff;
    font-weight: bold;
  }
}

#snipcart-detail {
  .snipcart-thumb{ 
    .snipcart-price {
      top: -10px;
      right: -30px;
      height: 125px;
      line-height: 125px;
      width: 125px;
      font-size: 1.25rem;
    }
  }
}

.snipcart-categories{
  padding-bottom: 50px;
  .snipcart-item.block {
    position: relative;
    width: 100%;
    margin: 0%;

    @include respond-to(small) {
      width: 49%;
      margin: 0.5%;
    }
    @include respond-to(medium) {
      width: 32%;
      margin: 0.5%;
    }
    @include respond-to(large) {
      width: 24%;
      margin: 0.5%;
    }


    h3{
      pointer-events: none;
      position:absolute;
      z-index: 5;
      font-size: 18px;
      line-height: 1.1em;
      top: 50%;
      transform: translateY(-50%);
      left: 40px;
      right: 40px;
      font-weight: bold;
      color: white;
      line-height: 1.5em;
      a{
        color: inherit;
      }
    }
  }
  .snipcart-thumb{
    a{
      img{
        opacity: .5;
        transition: opacity .35s ease;
      }
      display: relative;
      z-index: 4;
      &::after{
        content:"";
        display: block;
        position: absolute;
        background: url(../images/shop-cat.png) no-repeat center center;
        opacity: .5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity .35s ease;
      }
      &:hover{
        img{
          opacity: 1;
        }
        &::after{
          opacity: .95;
        }
      }
    }
  }
}

.snipcart-products{
  padding-bottom: 50px;
  text-align:center;
  display: flex;
  flex-wrap: wrap;
  .snipcart-item.block{
    vertical-align: top;

    width: 75%;
    margin: 5px auto;

    @include respond-to(small) {
      width: 49%;
      margin: 0.5%;
    }
    @include respond-to(medium) {
      width: 32%;
      margin: 0.5%;
    }
    @include respond-to(large) {
      width: 24%;
      margin: 0.5%;
    }


    float: none;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    h3{
      min-height: 3.6em;
    }
    .snipcart-thumb{
      flex-grow: 1;
    }
    .snipcart-details{

    }
  }
}

.snipcart-item{
  h3{
    color: black;
    a{
      color: inherit;
    }
  }
}