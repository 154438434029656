@charset 'UTF-8';

// 1. Configuration and helpers
@import
  'abstracts/variables',
  'abstracts/mixins';

// 2. Vendors
@import  
//  'vendor/font-awesome/fontawesome',
//  'vendor/font-awesome/brands',
//  'vendor/font-awesome/regular',
//  'vendor/font-awesome/solid',
//  'vendor/font-awesome/v4-shims',
  'vendor/font-line/line-awesome',
  'vendor/_slick';

// 3. Base stuff
@import
  'base/global',
  'base/helpers';

// 4. Layout-related sections
@import
  'layout/header',
  'layout/footer';

  
// 5. Partials
  @import
    'partials/modular',
    'partials/snipcart';

// 6. Components
@import
    'components/_form',
    'components/_slider';
